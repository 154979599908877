<template>
  <div class="stock-list">
    <div class="text-h4 font-weight-bold mb-sm-6 mb-3 px-sm-0 px-4">{{ $t('stockList.title') }}</div>
    <v-card :color="$vuetify.breakpoint.xs ? 'accent' : ''" width="100%" rounded="lg" flat class="px-md-9 px-4 pb-sm-7 pt-sm-8">
      <div class="width100">
        <v-text-field
          @keyup="getData()"
          class="field64 accent"
          v-model="search"
          :placeholder="$vuetify.breakpoint.xs ? $t('stockList.searchMob') : $t('stockList.search')"
          hide-details
          outlined
          required
          color="primary"
          height="68"
        >
          <template v-slot:prepend-inner>
            <img width="24" height="24" src="@/assets/icon/search.svg" />
          </template>
        </v-text-field>
        <div class="width100">
          <div class="d-flex ms-3 mb-sm-11 mb-3 mt-5">
            <div class="gray--text me-5">{{ $t('stockList.results') }}: {{ data.total || 0 }}</div>
            <div class="font-weight-medium d-sm-block d-none">
              <span class="gray--text">{{ $t('stockList.sort') }}: </span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="black--text">
                    <span class="text-capitalize">{{ sort.split('_')[0] }} </span>
                    <span class="text-uppercase">{{ sort.split('_')[1] }}</span>
                  </span>
                </template>
                <v-list>
                  <v-list-item @click="sortRequest(i)" v-for="i in sortList" :key="i">
                    <span class="text-capitalize pe-1">{{ i.split('_')[0] }}</span>
                    <span class="text-uppercase">{{ i.split('_')[1] }}</span>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <v-btn depressed class="mb-5 primary d-sm-none" width="100%" height="48" @click="modal = true">
            <v-icon>mdi-swap-vertical</v-icon>
            <span class="text-body-2">
              <span class="text-capitalize pe-1 ps-3">{{ sort.split('_')[0] }}</span>
              <span class="text-uppercase">{{ sort.split('_')[1] }}</span>
            </span>
          </v-btn>
          <div class="grid-card" :style="!data.results ? 'grid-template-columns: auto' : ''">
            <div v-if="!data.results" class="text-center mx-auto mb-5">
              <img src="@/assets/icon/noStock.svg" alt="no-data" />
              <div class="f18 font-weight-bold">{{ $t('stockList.noData') }}</div>
            </div>
            <v-hover v-slot="{ hover }" v-for="item in data.results" :key="item.id">
              <v-card
                @click.prevent="selectCard(item.id)"
                max-width="470"
                width="100%"
                class="px-4 pt-5 pb-6 mb-5 black--text rounded-lg"
                outlined
                :elevation="hover ? 8 : 0"
                :class="{ 'on-hover': hover }"
              >
                <div class="d-flex justify-space-between">
                  <div>
                    <div class="text-h5 font-weight-bold">{{ item.exchange_symbol }}</div>
                    <div class="gray--text font-weight-medium mt-1">{{ item.exchange_id }}</div>
                    <div class="mt-6 d-flex align-center">
                      <span class="text-uppercase text-h6 font-weight-medium">
                        {{ item.price_currency }} {{ (item.price / 100).toFixed(2) }}
                      </span>
                      <span class="gray--text ms-4 text-sm-body-1 text-body-2"><span class="triangle-top"></span> +32,26 (5,36 %)</span>
                    </div>
                    <div class="mt-6">
                      <v-btn
                        :class="item.portfolio_percentage ? 'primary white--text' : 'accent'"
                        outlined
                        :height="$vuetify.breakpoint.xs ? 48 : 60"
                        :width="$vuetify.breakpoint.xs ? 216 : 240"
                        @click.stop="item.portfolio_percentage ? '' : addItem(item)"
                      >
                        <img height="24" :src="getImage(item.portfolio_percentage ? 'portfolioW' : 'portfolio')" class="pe-3" />
                        <span v-if="item.portfolio_percentage" class="pe-3">{{ item.portfolio_percentage / 100 }}%</span>
                        {{ item.portfolio_percentage ? $t('btn.inPorfolio') : $t('btn.addPorfolio') }}
                      </v-btn>
                    </div>
                  </div>
                  <v-img
                    cover
                    :height="$vuetify.breakpoint.xs ? 56 : 90"
                    :max-width="$vuetify.breakpoint.xs ? 56 : 90"
                    :src="item.company_picture"
                  ></v-img>
                </div>
              </v-card>
            </v-hover>
          </div>
        </div>
      </div>
    </v-card>
    <v-snackbar :timeout="3000" v-model="snackbar" centered top tile color="error">{{ $t('portfolio.stockAlreadyAdd') }}.</v-snackbar>
    <v-dialog v-model="modal" max-width="1200" width="100%">
      <v-card class="px-4 py-6 text-center">
        <div class="d-flex mb-4 text-start">
          <div>
            <div class="f18 mb-1">{{ $t('stockList.sort') }}</div>
            <div class="gray--text text-body-2">{{ $t('stockList.modalDesc') }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn @click="modal = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-list>
          <v-list-item @click="sort = i" v-for="i in sortList" :key="i" class="px-2">
            <v-icon v-if="sort != i" color="gray lighten-1">mdi-circle-outline</v-icon>
            <v-icon v-else color="primary">mdi-record-circle</v-icon>
            <span class="text-capitalize pe-1 ps-3">{{ i.split('_')[0] }}</span>
            <span class="text-uppercase">{{ i.split('_')[1] }}</span>
          </v-list-item>
        </v-list>
        <v-btn @click="sortRequest('')" depressed width="230" height="48" class="mt-5 primary">{{ $t('btn.confirm') }}</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      snackbar: false,
      modal: false,
      page: +this.$route.query.page || 1,
      sector: +this.$route.query.sector ? this.$route.query.sector.split(',') : [],
      sort: this.$route.query.sort || 'symbol_asc',
      riskProfile: this.$route.query.risk_profile || '',
      search: this.$route.query.src || '',
      sortList: ['symbol_asc', 'symbol_desc', 'price_asc', 'price_desc'],
      error: [],
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getMarketsItemSectors', { id: 1, risk: '' });
  },
  methods: {
    getImage(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
    async addItem(i) {
      this.error = [];
      const data = new Object();
      data.percent = 0;
      data.security = i.id;
      await this.$store
        .dispatch('addPlanItem', data)
        .then(() => {
          this.$router.push('/portfolio/plan');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.error.find((i) => i == 'security__already_added') ? (this.snackbar = true) : '';
          this.error.find((i) => i == 'questionnaire__not_provided') ? this.$router.push(`/question/start?page=${this.$route.path}`) : '';
        });
    },
    async getData() {
      const data = new Object();
      data.page = this.page;
      data.sector = this.sector;
      data.sort = this.sort;
      data.riskProfile = this.riskProfile;
      data.search = this.search;
      await this.$store.dispatch('getSecuritiesList', data);
    },
    sortRequest(i) {
      i ? (this.sort = i) : '';
      setTimeout(() => {
        this.getData();
        this.modal = false;
      }, 100);
    },
    getIcon(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
    selectCard(id) {
      this.$router.push(`/stock/${id}`);
    },
  },
  watch: {
    modal() {
      setTimeout(() => {
        this.sort = new URLSearchParams(window.location.search).get('sort') || 'symbol_asc';
      }, 300);
    },
  },
  computed: {
    data() {
      return this.$store.getters.securitiesList;
    },
  },
  destroyed() {
    this.$store.dispatch('setSecuritiesList', []);
  },
};
</script>

<style lang="scss">
#app .stock-list {
  label {
    color: var(--v-black-base);
  }
  .field64 {
    fieldset {
      margin-top: -3px;
    }
    .v-text-field__slot {
      margin-top: 2px !important;
    }
  }
  .grid-card {
    display: grid;
    width: 100%;
    justify-items: center;
    grid-template-columns: auto auto auto;
    grid-column-gap: 24px;
  }
  @media screen and (max-width: 1589px) {
    .grid-card {
      grid-template-columns: auto auto;
    }
  }
  @media screen and (max-width: 849px) {
    .grid-card {
      grid-template-columns: auto;
    }
  }
  @media screen and (max-width: 599px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .text-h6 {
      font-size: 1.125rem;
    }
  }
}
</style>
